import * as React from "react";
import { ConditionNodeOption, WorkflowNode } from "../../types";
import { SecondaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import IconPlus from "@velaro/velaro-shared/src/icons/IconPlus";
import CloseIcon from "@velaro/velaro-shared/src/svg/stroke-icons/x-close.svg?react";
import { useState } from "react";
import IconEditPencil3 from "@/icons/IconEditPencil3";
import { FormGroup } from "../RightAsideHelpers";
import Input from "@/components/Input";
import { ExpressionEditor } from "@/components/RuleBuilder2/ExpressionEditor";
interface Props {
  node: WorkflowNode;
  onUpdate: (node: Partial<WorkflowNode>) => void;
}

export default function ConditionEditor({ node, onUpdate }: Props) {
  const [editingNameIndex, setEditingNameIndex] = useState<number | null>(null);

  const options = node.conditionOptions || [];

  function addBranch() {
    onUpdate({
      conditionOptions: [
        ...options,
        {
          expression: []
        }
      ]
    });
  }

  function removeBranch(index: number) {
    onUpdate({
      conditionOptions: options.filter((_, i) => i !== index)
    });
  }

  function updateBranch(index: number, option: ConditionNodeOption) {
    onUpdate({
      conditionOptions: options.map((c, i) => (i === index ? option : c))
    });
  }

  function renderOption(option: ConditionNodeOption, i: number) {
    const name = getConditionNodeName(option, i);
    return (
      <div key={i} className="border rounded-lg p-2 mb-4">
        <div className="flex justify-between">
          <div className="group flex gap-2 items-center font-medium">
            <>
              {editingNameIndex === i && (
                <input
                  autoFocus
                  onBlur={() => setEditingNameIndex(null)}
                  onChange={(e) => {
                    updateBranch(i, { ...option, label: e.target.value });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") setEditingNameIndex(null);
                  }}
                  type="text"
                  value={name}
                  className="border-none bg-inherit p-1"
                />
              )}
              {editingNameIndex !== i && (
                <>
                  {name}
                  <div
                    onClick={() => setEditingNameIndex(i)}
                    className="cursor-pointer hidden group-hover:inline"
                  >
                    <IconEditPencil3 className="hover:stroke-blue-500 w-5 h-5" />
                  </div>
                </>
              )}
            </>
          </div>
          <CloseIcon
            onClick={() => removeBranch(i)}
            className="text-slate-500 cursor-pointer"
          />
        </div>
        <div className="my-2">
          <ExpressionEditor
            expression={option.expression}
            onUpdate={(expression) => {
              onUpdate({
                conditionOptions: options.map((c, index) =>
                  index === i ? { ...c, expression } : c
                )
              });
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <>
      <FormGroup label="Description">
        <Input
          value={node.description || ""}
          onChange={(description) => onUpdate({ description })}
        />
      </FormGroup>
      {options.map(renderOption)}
      <SecondaryBlueButton
        label="Add Branch"
        prefixIcon={<IconPlus />}
        onClick={addBranch}
        className="w-full"
      />
    </>
  );
}

export function getConditionNodeName(option: ConditionNodeOption, i: number) {
  return option.label ?? "Branch " + (i + 1);
}
