import Select from "@/components/Select";
import React from "react";
import { comparatorOptions, SerializedCondition } from "../types";
import { Channel, channelOptions } from "@/types";

export type ChannelCondition = {
  channel: Channel;
  comparator: string;
};

export function ChannelEditor({
  condition,
  onUpdate
}: {
  condition: SerializedCondition;
  onUpdate: (condition: SerializedCondition) => void;
}) {
  const channelCondtion = JSON.parse(
    condition.data || "{}"
  ) as ChannelCondition;

  function update(data: ChannelCondition) {
    onUpdate({ ...condition, data: JSON.stringify(data) });
  }

  const validComparatorOptions = {
    equals: comparatorOptions.equals,
    notEquals: comparatorOptions.notEquals
  };

  return (
    <>
      <Select
        className="w-full border rounded-md p-2"
        options={validComparatorOptions}
        value={channelCondtion.comparator}
        onChange={(v) => update({ ...channelCondtion, comparator: v })}
      />
      <Select<Channel>
        className="w-full border rounded-md p-2"
        options={channelOptions}
        value={channelCondtion.channel}
        onChange={(v) => update({ ...channelCondtion, channel: v })}
      />
    </>
  );
}
