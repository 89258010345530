import React from "react";
import { comparatorOptions, SerializedCondition } from "../types";
import Select from "@/components/Select";

export type AttributeCondition = {
  attribute: string;
  comparator: string;
  value: string;
};

export function AttributeEditor({
  condition,
  onUpdate
}: {
  condition: SerializedCondition;
  onUpdate: (condition: SerializedCondition) => void;
}) {
  const attributeCondtion = JSON.parse(
    condition.data || "{}"
  ) as AttributeCondition;

  function update(data: AttributeCondition) {
    onUpdate({ ...condition, data: JSON.stringify(data) });
  }

  return (
    <>
      <input
        type={"text"}
        placeholder="Attribute Name"
        value={attributeCondtion.attribute || ""}
        onChange={(e) => {
          update({ ...attributeCondtion, attribute: e.target.value });
        }}
        className="border rounded-md p-2 w-full"
      />
      <Select
        className="w-full border rounded-md p-2"
        options={comparatorOptions}
        value={attributeCondtion.comparator}
        onChange={(v) => update({ ...attributeCondtion, comparator: v })}
      />
      {!["exists", "notExists"].includes(attributeCondtion.comparator) && (
        <input
          type={"text"}
          placeholder="Value"
          value={attributeCondtion.value || ""}
          onChange={(e) => {
            update({ ...attributeCondtion, value: e.target.value });
          }}
          className="border rounded-md p-2 w-full"
        />
      )}
    </>
  );
}
