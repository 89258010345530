import useTeams from "@/hooks/useTeams";
import React from "react";

export default function TeamSelect(
  props: React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >
) {
  const { teams } = useTeams();

  return (
    <>
      <select {...props}>
        <option key={""} value={""}>
          -- Select --
        </option>
        {teams.map((team) => (
          <option key={team.id} value={team.id}>
            {team.name}
          </option>
        ))}
      </select>
    </>
  );
}
