import * as React from "react";
import { FormGroup } from "../RightAsideHelpers";
import Select from "@/components/Select";
import { WorkflowNode } from "../../types";
import useAIConfigs from "@/hooks/useAIConfigs";

interface Props {
  node: WorkflowNode;
  onUpdate: (node: Partial<WorkflowNode>) => void;
}

export default function TransferToAIEditor({ node, onUpdate }: Props) {
  const { aiConfigurations } = useAIConfigs();
  const aiOptions = aiConfigurations.map((ai) => {
    return { value: ai.id, label: ai.name };
  });

  return (
    <>
      <FormGroup label="AI Config">
        <Select
          className="w-full border rounded-md p-2"
          options={aiOptions}
          value={node.aiConfigId}
          onChange={(value) => {
            onUpdate({ aiConfigId: Number(value) });
          }}
        />
      </FormGroup>
    </>
  );
}
