import React, { useState } from "react";
import { PlusButton, MinusButton } from "../Buttons";
import ExpressionEditor from "./ExpressionEditor";
import { Expression, WorkflowRule } from "./types";
import Modal from "@velaro/velaro-shared/src/components/Modal";

export default function RuleEditor({
  rule,
  update,
  remove,
}: {
  rule: WorkflowRule;
  update(rule: WorkflowRule): void;
  remove(): void;
}) {
  const [removeModal, setRemoveModal] = useState(false);

  function addExpression() {
    const clone = { ...rule };
    clone.expressions.push({ channel: { comparator: "equals", value: "web" } });
    update(clone);
  }

  function removeExpression() {
    const clone = { ...rule };
    const last = clone.expressions.pop();
    if (!last) {
      return;
    }
    update(clone);
  }

  function updateExpression(expressionIndex: number, value: Expression) {
    const clone = { ...rule };
    clone.expressions[expressionIndex] = value;
    update(clone);
  }

  return (
    <div className="border-2 m-4 p-4">
      {rule.expressions.map((expression, i) => (
        <React.Fragment key={i}>
          <ExpressionEditor
            key={i}
            expression={expression}
            update={(e) => updateExpression(i, e)}
          />
          {i < rule.expressions.length - 1 && (
            <div className="border-b relative">
              <div className="border rounded-xl text-center w-min mx-auto bg-cornflower-blue-800 text-gray-100 text-sm px-2 absolute top-0 left-1/2 -translate-y-1/2">
                or
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
      <PlusButton label="or" onClick={addExpression} />
      {rule.expressions.length > 1 && (
        <MinusButton onClick={removeExpression} />
      )}
      <Modal show={removeModal}>
        <Modal.Header
          title="Confirm Rule Removal"
          onClose={() => setRemoveModal(!removeModal)}
        />
        <Modal.Body>
          <div className="p-2">
            Are you sure you&apos;d like to remove this rule? This action cannot
            be undone.
          </div>
          <div className="p-4 flex item">
            <div className="ml-auto">
              <button
                type="button"
                onClick={() => setRemoveModal(!removeModal)}
                className="btn-link mr-2"
              >
                Cancel
              </button>
              <button type="submit" className="btn-red" onClick={remove}>
                Remove Rule
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>{}</Modal.Footer>
      </Modal>
    </div>
  );
}
