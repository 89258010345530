import * as React from "react";
import { FormGroup } from "../RightAsideHelpers";
import { WorkflowNode } from "../../types";
import RadioLabel from "@/components/RadioLabel";
import TeamSelect from "@/components/messaging/TeamSelect";
import Select from "@/components/Select";
import useUsers from "@/hooks/useUsers";

interface Props {
  node: WorkflowNode;
  onUpdate: (node: Partial<WorkflowNode>) => void;
}

export default function TransferToAgentsEditor({ node, onUpdate }: Props) {
  const { userOptions } = useUsers();
  const specificTeam = !!node.specificTeam;
  const specificAgent = !!node.specificAgent;
  return (
    <>
      <FormGroup
        label="Transfer to Agents on"
        tooltipText="Routing rules for the team will be followed unless assigning to a specific agent"
      >
        <div className="flex flex-col gap-2">
          <RadioLabel checked={!specificTeam}>
            <input
              type="radio"
              checked={!specificTeam}
              onChange={(e) => {
                onUpdate({ specificTeam: !e.currentTarget.checked });
              }}
            />
            <label className="pl-2">the current Team</label>
          </RadioLabel>
          <RadioLabel checked={specificTeam}>
            <input
              type="radio"
              checked={specificTeam}
              onChange={(e) => {
                onUpdate({ specificTeam: e.currentTarget.checked });
              }}
            />
            <label className="pl-2">a specific Team</label>
          </RadioLabel>
        </div>
      </FormGroup>
      {specificTeam && (
        <>
          <FormGroup label="Team">
            <TeamSelect
              className="w-full border rounded-md p-2"
              value={node.teamId || ""}
              onChange={(e) => {
                onUpdate({ teamId: Number(e.currentTarget.value) });
              }}
            />
          </FormGroup>
          <FormGroup>
            <div className="flex cursor-pointer">
              <input
                type="checkbox"
                className="cursor-pointer"
                id={`specificAgent`}
                checked={specificAgent}
                onChange={(e) => {
                  onUpdate({ specificAgent: e.currentTarget.checked });
                }}
              />
              <label htmlFor={`specificAgent`} className="pl-2 cursor-pointer">
                specific Agent
              </label>
            </div>
          </FormGroup>

          {specificAgent && (
            <FormGroup label="Agent">
              <Select
                className="w-full border rounded-md p-2"
                options={userOptions}
                value={node.agentId}
                onChange={(agentId) => {
                  onUpdate({ agentId: Number(agentId) });
                }}
              />
            </FormGroup>
          )}
        </>
      )}
    </>
  );
}
