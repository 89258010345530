import React from "react";
import { SerializedCondition } from "../types";
import { DayOfWeek } from "@/components/RuleBuilder/types";
import { dayOfWeekOptions } from "@/pages/Messaging/Workflows/types";

export type DayOfWeekCondition = {
  daysOfWeek: DayOfWeek[];
};

export function DayOfWeekEditor({
  condition,
  onUpdate
}: {
  condition: SerializedCondition;
  onUpdate: (condition: SerializedCondition) => void;
}) {
  const dayOfWeekCondtion = JSON.parse(
    condition.data || "{}"
  ) as DayOfWeekCondition;

  dayOfWeekCondtion.daysOfWeek = dayOfWeekCondtion.daysOfWeek || [];

  function update(data: DayOfWeekCondition) {
    onUpdate({ ...condition, data: JSON.stringify(data) });
  }
  //random guid
  const id = Math.random();
  return (
    <>
      <div className="flex flex-wrap gap-2 justify-between">
        {dayOfWeekOptions.map((dayOfWeek, i) => (
          <div className="flex cursor-pointer" key={i}>
            <input
              type="checkbox"
              className="cursor-pointer"
              id={`scheduling-${dayOfWeek}-${id}`}
              checked={dayOfWeekCondtion.daysOfWeek.includes(dayOfWeek)}
              onChange={(e) => {
                if (e.target.checked) {
                  update({
                    ...dayOfWeekCondtion,
                    daysOfWeek: [...dayOfWeekCondtion.daysOfWeek, dayOfWeek]
                  });
                } else {
                  update({
                    ...dayOfWeekCondtion,
                    daysOfWeek: dayOfWeekCondtion.daysOfWeek.filter(
                      (d) => d !== dayOfWeek
                    )
                  });
                }
              }}
            />
            <label
              htmlFor={`scheduling-${dayOfWeek}-${id}`}
              className="ml-2 w-20 cursor-pointer"
            >
              {dayOfWeek}
            </label>
          </div>
        ))}
      </div>
    </>
  );
}
