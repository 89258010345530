import { NumberSchema } from "yup";
import { AIConfiguration } from "../Bots/types";
import {
  WorkflowNode,
  NodeOption,
  triggerDefs,
  actions,
  NodeColors,
  NodeId
} from "./types";

export function getNodeOptions(node: WorkflowNode) {
  if (node.type !== "action") {
    return null;
  }

  switch (node.actionType) {
    case "askQuestion":
      {
        if (node.questionType === "multipleChoice") {
          return node.options || [];
        } else if (node.invalidReplyAction === "failBranch") {
          return [
            { label: "Valid reply", childNodeId: node.child },
            { label: "Invalid reply", childNodeId: node.invalidChild }
          ] as NodeOption[];
        }
      }
      break;

    case "condition": {
      const options = [...(node.conditionOptions || [])] as NodeOption[];
      options.push({ label: "Else", childNodeId: node.fallbackChildId });
      return options;
    }
  }

  return null;
}

//index is based on the array returned by getNodeOptions
export function updateNodeOption(
  index: number,
  childId: NodeId | undefined,
  node: WorkflowNode
) {
  if (node.type !== "action") {
    return null;
  }

  switch (node.actionType) {
    case "askQuestion":
      {
        if (node.questionType === "multipleChoice") {
          const options = node.options || [];
          options[index].childNodeId = childId;
        } else if (node.invalidReplyAction === "failBranch") {
          if (index === 0) {
            node.child = childId;
          } else {
            node.invalidChild = childId;
          }
        }
      }
      break;

    case "condition": {
      const options = node.conditionOptions || [];
      if (index === options.length) {
        node.fallbackChildId = childId; //else branch
      } else {
        options[index].childNodeId = childId;
      }
    }
  }

  return null;
}

export function getNodeTitle(node: WorkflowNode) {
  if (node.type === "trigger") {
    return triggerDefs[node.trigger];
  } else {
    return actions[node.actionType].name;
  }
}

export function getNodeText(
  node: WorkflowNode,
  nodes: Record<NodeId, WorkflowNode>,
  aiConfigurations: AIConfiguration[],
  getDisplayNameForUser: (userId: number) => string,
  getTeamNameById: (teamId: number) => string
) {
  switch (node.type) {
    case "trigger":
      return "Start";
    case "action": {
      switch (node.actionType) {
        case "jumpTo": {
          if (!node.jumpToNodeId) {
            return "*No node chosen*";
          }

          const landingNode = nodes[node.jumpToNodeId];
          return landingNode.name || "unnamed node";
        }
        case "sendMessage":
          return node.text;
        case "askQuestion":
          return node.question;
        case "closeConversation":
          return "Close conversation";
        case "httpRequest":
        case "condition":
          return node.description;
        case "transferToAI": {
          if (!node.aiConfigId) {
            return "*No AI Configuration chosen*";
          }
          const ai = aiConfigurations.find((ai) => ai.id === node.aiConfigId);
          return ai?.name || "*AI Configuration missing*";
        }
        case "transferToAgents": {
          const agentName = getDisplayNameForUser(node.agentId || 0);
          const teamName = getTeamNameById(node.teamId || 0);
          if (node.specificTeam) {
            if (node.specificAgent)
              return `Transfer to ${agentName} on ${teamName}`;
            else return `Transfer to Agents on ${teamName}`;
          } else {
            return "Transfer to Agents on the current Team";
          }
        }
        case "setData": {
          const dataToSet = node.dataToSet;
          if (dataToSet?.length) {
            const firstData = dataToSet[0];
            const text = `Set ${firstData.name} to ${firstData.value}`;
            if (dataToSet.length > 1) {
              return text + " ... and more";
            }
            return text;
          } else {
            return "No data currently set";
          }
        }
        default:
          return "";
      }
    }
  }
}

export function getNodeColors(node: WorkflowNode): NodeColors {
  switch (node.type) {
    case "trigger":
      return {
        bgColor: "bg-blue-500",
        borderColor: "border-blue-500",
        optionColor: "bg-blue-500"
      };

    case "action":
      switch (node.actionType) {
        case "condition":
          return {
            bgColor: "bg-green-500",
            borderColor: "border-green-500",
            optionColor: "bg-green-500"
          };

        default:
          return {
            bgColor: "bg-grape-500",
            borderColor: "border-grape-500",
            optionColor: "bg-grape-500"
          };
      }
  }
}
