import {
  Condition,
  Expression,
  RuleValue,
  WorkflowRule,
  triggerDefs
} from "@/components/RuleBuilder/types";
import useAIConfigs from "@/hooks/useAIConfigs";
import useTeams from "@/hooks/useTeams";
import useWorkflowConfigs from "@/hooks/useWorkflowConfigs";
import { SecondaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import React from "react";
interface Props {
  rule: WorkflowRule;
  editRule(): void;
}
type ExpressionKey = keyof Expression;

const comparatorDefs = {
  equals: "is",
  notEquals: "is not",
  contains: "contains",
  notContains: "doesn't contain"
};
export default function RuleRowItem({ rule, editRule }: Props) {
  const { remove } = useWorkflowConfigs();
  const { aiConfigurations } = useAIConfigs();
  const { getTeamNameById } = useTeams();
  //this gives us a name instead of the id
  function getRuleValueLabel(key: string, value: RuleValue) {
    const result = value[key as keyof typeof rule.value] as string;
    switch (rule.trigger) {
      case "use-ai":
        return aiConfigurations.find((x) => x.id === Number(result))?.name;
      default:
        return result;
    }
  }

  function getExpressionValueLabel(condition: Condition, value: any) {
    switch (condition) {
      case "teams":
        return (value as number[])
          .map((teamId) => getTeamNameById(Number(teamId)))
          .join(", ");

      default:
        return value;
    }
  }

  return (
    <div
      onClick={editRule}
      className="flex flex-row gap-20 items-center border p-4 m-4 bg-slate-50 hover:bg-cornflower-blue-50 hover:border-cornflower-blue-100 cursor-pointer"
    >
      <span className="flex gap-4">
        <span>{triggerDefs[rule.trigger as keyof typeof triggerDefs]}</span>
        <span className="flex flex-col">
          {Object.keys(rule.value).map(
            (k) => ` ${k}: ${getRuleValueLabel(k, rule.value)} `
          )}
        </span>
        <span className="font-bold">WHEN</span>
        <span className="flex flex-col">
          {rule.expressions.map((expression: Expression, i) => {
            const conditions = Object.keys(expression) as ExpressionKey[];
            return (
              <React.Fragment key={i}>
                <div className="flex gap-2">
                  {conditions.map((condition, j) => (
                    <React.Fragment key={j}>
                      <span>{`${condition} ${
                        comparatorDefs[
                          expression[condition]?.comparator || "contains"
                        ]
                      } ${getExpressionValueLabel(
                        condition,
                        expression[condition]?.value
                      )}`}</span>
                      {j !== conditions.length - 1 && (
                        <span className="font-bold">AND</span>
                      )}
                    </React.Fragment>
                  ))}
                </div>
                {i !== rule.expressions.length - 1 && (
                  <div className="font-bold">OR</div>
                )}
              </React.Fragment>
            );
          })}
        </span>
      </span>
      <span className="flex flex-col">
        <SecondaryBlueButton
          onClick={(e) => {
            e.stopPropagation();
            remove(rule);
          }}
          label="Delete"
        />
      </span>
    </div>
  );
}
