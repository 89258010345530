import Select from "@/components/Select";
import React from "react";
import { conditionDefs, conditionOptions, SerializedCondition } from "./types";
import IconClose from "@velaro/velaro-shared/src/icons/IconClose";

export interface ConditionProps {
  condition: SerializedCondition;
  onUpdate: (condition: SerializedCondition) => void;
  onRemove?: () => void;
}

export function ConditionBlock({
  condition,
  onUpdate,
  onRemove
}: ConditionProps) {
  const Editor = condition.type ? conditionDefs[condition.type].editor : null;
  return (
    <div className="flex flex-col gap-4 px-3 py-4 bg-white rounded-lg border border-slate-30 justify-center items-start">
      <div className="w-full flex gap-2 items-center justify-between">
        <Select
          className="w-full border rounded-md p-2"
          options={conditionOptions}
          value={condition.type}
          onChange={(v) => onUpdate({ ...condition, type: v })}
        />
        <IconClose
          onClick={onRemove}
          className="cursor-pointer"
          style={{ color: "#CBD5E1" }}
        />
      </div>
      {Editor && <Editor condition={condition} onUpdate={onUpdate} />}
    </div>
  );
}
