import { Team } from "@/pages/Messaging/Teams/types";
import { SelectOption } from "@/types";
import React from "react";
export const TeamsContext = React.createContext<{
  teams: Team[];
  getTeamNameById: (teamId: number) => string;
  setTeams: (teams: Team[]) => void;
  getTeamById: (TeamId: number) => Team | null;
  getTeamOptions: () => SelectOption[];
}>({
  teams: [],
  getTeamNameById: () => "",
  setTeams: () => {},
  getTeamById: () => null,
  getTeamOptions: () => []
});
