import React, { useCallback, useEffect, useState } from "react";
import AIChatbotSettingsTabs from "./AIChatbotSettingsTabs";
import { useParams } from "react-router-dom";
import useAIConfigs from "@/hooks/useAIConfigs";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import AIChatbotSettingsTrainingTabs from "./AIChatbotSettingsTrainingTabs";
import AIChatbotSettingsTrainingTabWebsite from "./AIChatbotSettingsTrainingTabWebsite";
import AIChatbotSettingsTrainingTabSiteMap from "./AIChatbotSettingsTrainingTabSiteMap";
import AIChatbotSettingsTrainingTabFile from "./AIChatbotSettingsTrainingTabFile";
import useApi from "@/hooks/useApi";
import { SecondaryGrayButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import IconFile from "@velaro/velaro-shared/src/svg/stroke-icons/file-04.svg?react";
import IconRefresh from "@velaro/velaro-shared/src/svg/stroke-icons/refresh-cw-05.svg?react";
import SortableTable from "@velaro/velaro-shared/src/components/SortableTable";
import AIChatbotSettingsTrainingTabText from "./AIChatbotSettingsTrainingTabText";
import IconFilter from "@velaro/velaro-shared/src/svg/stroke-icons/filter-lines.svg?react";

export default function AIChatbotSettingsTraining() {
  const { id } = useParams();
  const { loading } = useAIConfigs();
  const [selectedTab, setSelectedTab] = useState("Website");
  const [scrapedUrls, setScrapedUrls] = useState<string[]>([]);
  const api = useApi();

  const fetchUrls = useCallback(async () => {
    //setLoading(true);
    const json = await api.messaging.get(`AIConfiguration/Urls/${id}`);
    const result = (await json.json()) as string[];

    setScrapedUrls(result);
    //setLoading(false);
  }, [api.messaging, id]);

  useEffect(() => {
    fetchUrls();
  }, [fetchUrls, id]);

  const columns = [
    { label: "Title", accessor: "title" },
    { label: "Type", accessor: "type" },
    { label: "Characters", accessor: "characters" },
    { label: "Status", accessor: "status" },
    { label: "", accessor: "edit" }
  ];

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <AIChatbotSettingsTabs selectedTab="Training" aiConfigId={id!} />
      <div className="bg-white p-6 border-[1px] rounded-2xl shadow-sm w-full self-center">
        <div className="">
          <div className="font-semibold text-lg pb-1">Data Source</div>
          <div className="text-slate-500 text-sm pb-2">
            Supply information for the chatbot to train on and improve its
            responses.
          </div>
        </div>
        <AIChatbotSettingsTrainingTabs setSelectedTab={setSelectedTab} />
        {selectedTab === "Website" && <AIChatbotSettingsTrainingTabWebsite />}
        {selectedTab === "Site Map" && <AIChatbotSettingsTrainingTabSiteMap />}
        {selectedTab === "File" && <AIChatbotSettingsTrainingTabFile />}
        {selectedTab === "Text" && <AIChatbotSettingsTrainingTabText />}
      </div>
      {/* <div className="bg-white p-6 border-[1px] rounded-2xl shadow-sm w-full self-center mt-4">
        <div className="">
          <div className="font-semibold text-lg pb-1">Trained Data</div>
          <div className="flex">
            <div className="text-slate-500 text-sm pb-2">
              List of all data sources of your AI chatbot.
            </div>
            <div className=" text-sm pb-1 flex ml-auto ">
              <span className="pr-2 text-slate-500 ">Data Used:</span>
              <span className="font-semibold"> 0/50</span>
              <span className="pl-2 text-cornflower-blue-400 underline font-semibold">
                Upgrade Plan
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center py-3">
          <span className="flex pr-2">
            <SecondaryGrayButton
              label="Filter"
              onClick={() => {
                console.log("filter clicked");
              }}
              prefixIcon={<IconFilter className="w-4 h-4" />}
            />
          </span>
          <span>
            <SecondaryGrayButton
              label="Uploaded Files"
              onClick={() => {
                console.log("upload clicked");
              }}
              prefixIcon={<IconFile className="w-4 h-4" />}
            />
          </span>
          <span className="pl-2">
            <SecondaryGrayButton
              label="Retrain"
              onClick={() => {
                console.log("retrain clicked");
              }}
              prefixIcon={<IconRefresh className="w-4 h-4" />}
            />
          </span>
          <div className="flex ml-auto">
            <input
              type="text"
              //value={trainingUrl}
              //onChange={(e) => setTrainingUrl(e.target.value)}
              placeholder="Search"
              className="w-full p-2 border-[1px] rounded-md text-sm"
            />
          </div>
        </div>
        <SortableTable
          columns={columns}
          tableData={scrapedUrls}
          setTableData={setScrapedUrls}
        />
      </div> */}
    </>
  );
}
