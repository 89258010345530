import { UseAIValue } from "./ValueForms/AIRuleForm";
import { AutoResolveChatValue } from "./ValueForms/AutoResolveChatRuleForm";
import { UseAutomationValue } from "./ValueForms/AutomationRuleForm";
import { CreateCrmRecordValue } from "./ValueForms/CreateCrmRecordRuleForm";
import { LanguageConfigValue } from "./ValueForms/LanguageConfigRuleForm";
import { StyleConfigValue } from "./ValueForms/StyleConfigRuleForm";

export type RuleValue =
  | UseAutomationValue
  | UseAIValue
  | CreateCrmRecordValue
  | AutoResolveChatValue
  | LanguageConfigValue
  | StyleConfigValue;

export const comparatorOptions = {
  equals: "is",
  notEquals: "is not",
  contains: "contains",
  notContains: "doesn't contain"
};
export type Comparator = keyof typeof comparatorOptions;

export const triggerDefs = {
  "use-automation": "Use Automation",
  "use-ai": "Use AI",
  "route-to-group": "Route to Group",
  "create-crm-record": "Create CRM Record",
  "get-design-settings": "Get Design Settings",
  "resolve-chat": "Resolve Chat",
  "determine-language-config": "Determine Language Config",
  "determine-style-config": "Determine Style Config"
};

export type Trigger =
  | "use-automation"
  | "use-ai"
  | "route-to-group"
  | "create-crm-record"
  | "get-design-settings"
  | "resolve-chat"
  | "determine-language-config"
  | "determine-style-config";

export const dayOfWeekDefs = {
  mon: "monday",
  tues: "tuesday",
  wed: "wednesday",
  thurs: "thursday",
  fri: "friday",
  sat: "saturday",
  sun: "sunday"
};
export type DayOfWeek = keyof typeof dayOfWeekDefs;

export const agentStatusDefs = {
  available: "available",
  unavaialble: "unavailable"
};
export type AgentStatus = keyof typeof agentStatusDefs;

export const conditionDefs = {
  channel: "Source",
  dayOfWeek: "Day of Week",
  agentStatus: "Agent Status",
  domain: "Domain",
  url: "Url",
  teams: "Teams"
};
export type Condition = keyof typeof conditionDefs;

export const crmDefs = {
  NetSuite: "netsuite",
  SalesForce: "salesforce"
};
export type Crm = keyof typeof crmDefs;

export const crmRecordDefs = {
  Customer: "Customer",
  Account: "Account",
  SupportCase: "Support Case",
  Lead: "Lead",
  Prospect: "Prospect",
  Contact: "Contact",
  Order: "Order"
};
export type CrmRecord = keyof typeof crmRecordDefs;

export type Expression = Partial<
  Record<Condition, { comparator: Comparator; value: any }>
>; //Expressions - A record of ConditionType properties that must all evaluate to true for the condition to pass (AND)

export interface WorkflowRule {
  // A collection of expressions and a priority for a trigger
  id?: number;
  trigger: Trigger; //spot in the app where a rule needs to run
  priority: number; //order to run rules for a trigger
  expressions: Expression[]; // a collection of expressions. only one must be true for the rule to pass (OR)
  value: RuleValue; //if any expressions in this rule evaluate to true, this is the result to return
}
