import { WorkflowRule } from "@/components/RuleBuilder/types";
import React from "react";
export const WorkflowRulesContext = React.createContext<{
  workflowRules: WorkflowRule[];
  loading: boolean;
  saving: boolean;
  add(rule: WorkflowRule): void;
  update(rule: WorkflowRule): void;
  remove(rule: WorkflowRule): void;
}>({
  workflowRules: [],
  loading: false,
  saving: false,
  add() {},
  update() {},
  remove() {}
});
