import React from "react";

export interface ToastAlertSettings {
  stickyAlerts: boolean;
  stickyDuration: number;
}

export interface MenuItem {
  label: string;
  to?: string;
  icon: any;
  sublinks?: SubLink[];
  enabled?: boolean;
}

export interface SubLink {
  label: string;
  to: string;
  enabled?: boolean;
}

export type SvgIcon = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string;
  }
>;

export interface TimeZone {
  id: string;
  displayName: string;
}

export interface SelectOption {
  value: string | number;
  label: string;
}

export const channelDefs = {
  web: "Web",
  facebook: "Facebook",
  twitter: "Twitter",
  email: "Email",
  whatsApp: "WhatsApp",
  twilioIvr: "Twilio IVR",
  twilioSms: "Twilio SMS"
};
export type Channel = keyof typeof channelDefs;
export const channelOptions: SelectOption[] = Object.keys(channelDefs).map(
  (key: string) => {
    return {
      label: channelDefs[key as Channel],
      value: key
    };
  }
);
