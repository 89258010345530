import LoadingSpinner from "@/components/LoadingSpinner";
import TeamSelect from "@/components/messaging/TeamSelect";
import useApi from "@/hooks/useApi";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import SurveyEditor from "./SurveyEditor";
import { Survey } from "./types";
import useSelectedTeam from "@/hooks/useSelectedTeam";
import Panel from "@/components/Panel";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";

export default function Surveys() {
  const api = useApi();

  const [loading, setLoading] = useState(true);
  const { teamId, updateTeamId } = useSelectedTeam();
  const [surveys, setSurveys] = useState<Record<number, Survey>>({});
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = React.useState<Record<string, string>[]>([]);
  const toastAlert = useToastAlert();

  const survey = useMemo(() => {
    if (!teamId) {
      return null;
    }

    return (
      surveys[teamId] || {
        questions: [],
        teamId: teamId
      }
    );
  }, [surveys, teamId]);

  const ready = useMemo(() => !loading && survey, [loading, survey]);

  const fetch = useCallback(async () => {
    const response = await api.messaging.get("Surveys/List");
    setSurveys(await response.json());
    setLoading(false);
  }, [api.messaging]);

  const save = useCallback(async () => {
    if (!survey) {
      return;
    }
    let errors: Record<string, string>[] = [];
    setErrors(errors);

    survey.questions.forEach((q, i) => {
      if (!q.text) {
        const clone = [...errors];
        clone[i] = { text: "Question text is required" };
        setErrors(clone);
        errors = clone;
      }
    });
    if (errors.length > 0) {
      return;
    }

    setSaving(true);
    const resp = await api.messaging.post("Surveys", survey);
    if (resp.ok) {
      toastAlert.displayToast(Severity.Success, "Survey saved successfully");
    } else {
      toastAlert.displayToast(Severity.Error, "Failed to save survey");
    }
    setSaving(false);
  }, [api.messaging, survey, toastAlert]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (!ready) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md mb-4 p-4">
        <TeamSelect
          className="input overflow-ellipsis whitespace-nowrap w-64"
          onChange={(e) => {
            updateTeamId(Number(e.currentTarget.value));
          }}
          value={Number(teamId)}
        />
      </div>
      <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md mb-4 p-4">
        <div className="overflow-auto">
          <SurveyEditor
            errors={errors}
            survey={survey!}
            update={(value) => {
              const clone = { ...surveys };
              clone[teamId!] = value;
              setSurveys(clone);
            }}
          />
          <button
            disabled={saving}
            type="submit"
            className="btn-green"
            onClick={save}
          >
            {saving ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </>
  );
}
