import React from "react";
import { comparatorOptions, SerializedCondition } from "../types";
import Select from "@/components/Select";

export type DateCondition = {
  date: string;
  comparator: string;
};

export function DateEditor({
  condition,
  onUpdate
}: {
  condition: SerializedCondition;
  onUpdate: (condition: SerializedCondition) => void;
}) {
  const dateCondtion = JSON.parse(condition.data || "{}") as DateCondition;

  function update(data: DateCondition) {
    onUpdate({ ...condition, data: JSON.stringify(data) });
  }

  const validComparatorOptions = {
    equals: comparatorOptions.equals,
    greaterThan: comparatorOptions.greaterThan,
    lessThan: comparatorOptions.lessThan
  };

  return (
    <>
      <Select
        className="w-full border rounded-md p-2"
        options={validComparatorOptions}
        value={dateCondtion.comparator}
        onChange={(v) => update({ ...dateCondtion, comparator: v })}
      />
      <input
        type={"date"}
        value={dateCondtion.date || ""}
        onChange={(e) => {
          update({ ...dateCondtion, date: e.target.value });
        }}
        className="border rounded-md p-2 w-full"
      />
    </>
  );
}
