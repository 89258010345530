import React from "react";

export default function Input({
  placeholder,
  className,
  value,
  prefixIcon,
  suffixIcon,
  onChange
}: {
  placeholder?: string;
  className?: string;
  value: string;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  onChange(value: string): void;
}) {
  return (
    <div
      className={`px-3 w-full h-10 bg-white rounded-lg border border-slate-300 justify-start items-center flex gap-2 text-sm ${className}`}
    >
      {prefixIcon}
      <input
        placeholder={placeholder}
        type="text"
        className="w-full hover:border-none focus:border-none focus:outline-none"
        value={value || ""}
        onChange={(e) => onChange(e.currentTarget.value as string)}
      />
      {suffixIcon}
    </div>
  );
}
