import React, { useState } from "react";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

export default function AIChatbotSettingsTrainingTabWebsite() {
  const [trainingUrl, setTrainingUrl] = useState("");

  return (
    <>
      <input
        type="text"
        value={trainingUrl}
        onChange={(e) => setTrainingUrl(e.target.value)}
        placeholder="Enter a url"
        className="w-full p-2 border-[1px] rounded-md text-sm mt-4"
      />
      <div className="flex items-center mt-1">
        <input type="checkbox" className="p-2 text-sm" />
        <label className="text-slate-500 text-sm p-2">
          Fetch all links within this page
        </label>
      </div>

      <div className="flex justify-end">
        <PrimaryBlueButton
          label="Next"
          disabled={trainingUrl.length === 0}
          onClick={() => console.log("button clicked")}
        />
      </div>
    </>
  );
}
