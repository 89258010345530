import React, { useEffect, useState } from "react";
import CustomizableSelect from "@velaro/velaro-shared/src/components/inputs/CustomizableSelect";
import Toggle from "@velaro/velaro-shared/src/components/Toggle";
import AIChatbotSettingsRow from "./AIChatbotSettingsRow";
import AIChatbotSettingsRowToggle from "./AIChatbotSettingsRowToggle";
import AIChatbotSettingsTabs from "./AIChatbotSettingsTabs";
import { useParams } from "react-router-dom";
import useAIConfigs from "@/hooks/useAIConfigs";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import { AIConfiguration } from "../types";
import useTeams from "@/hooks/useTeams";
import useApi from "@/hooks/useApi";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import { User } from "../../Users/types";

export default function AIChatbotSettingsHandoff() {
  const [toggleEscalation, setToggleEscalation] = useState(false);
  const [toggleAISummarize, setToggleAISummarize] = useState(false);
  const { id } = useParams();
  const { aiConfigurations, loading, update } = useAIConfigs();
  const [config, setConfig] = useState<AIConfiguration>();
  const [configIndex, setConfigIndex] = React.useState<number>();
  const [escalationPhrases, setEscalationPhrases] = useState("");
  const [textAreaFocused, setTextAreaFocused] = useState(false);
  const { teams } = useTeams();
  const api = useApi();
  const toastAlert = useToastAlert();
  const [users, setUsers] = React.useState<User[]>([]);
  const [selectedAssignment, setSelectedAssignment] = React.useState("");

  function handleAssignment(arg0: string) {
    console.log(arg0);
    setSelectedAssignment(arg0);
  }

  function handleTeam(arg0: string) {
    console.log(arg0);
  }
  function handleAgent(arg0: string) {
    console.log(arg0);
  }

  const assignment = [
    {
      label: "Team",
      action: () => handleAssignment("team")
    },
    {
      label: "Agent",
      action: () => handleAssignment("agent")
    }
  ];

  React.useEffect(() => {
    async function fetchAgents() {
      const response = await api.messaging.get(`Users/List/`);
      if (!response.ok) {
        toastAlert.displayToast(Severity.Error, "Failed to fetch agents");
      }
      setUsers(await response.json());
    }

    fetchAgents();
  }, [api.messaging, toastAlert]);

  useEffect(() => {
    if (aiConfigurations.length > 0 && id) {
      const configIndex = aiConfigurations.findIndex(
        (config) => config.id === parseInt(id)
      );
      if (configIndex) {
        setConfig(aiConfigurations[configIndex]);
        setConfigIndex(configIndex);
      }
    }
  }, [aiConfigurations, id]);

  const handleChange = (e: any) => {
    if (!textAreaFocused) {
      setTextAreaFocused(true);
      setEscalationPhrases("1. " + e);
      return;
    }
    if (e.length >= 10000) return;
    if (e.length === 0) setTextAreaFocused(false);
    setEscalationPhrases(e);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const lines = escalationPhrases.split("\n");
      const nextNumber = lines.length + 1;
      setEscalationPhrases(escalationPhrases + `\n${nextNumber}. `);
    }
  };

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <AIChatbotSettingsTabs selectedTab="Handoff" aiConfigId={id!} />
      <div className="bg-white p-6 border-[1px] rounded-2xl shadow-sm w-[750px] self-center">
        <div className="border-b-[1px]">
          <div className="font-semibold text-lg pb-1">Agent Handoff</div>
          <div className="text-slate-500 text-sm pb-2">
            Choose how the chatbot will behave when it can&apos;t answer a
            question or when a visitor asks for agent help
          </div>
        </div>
        <AIChatbotSettingsRowToggle
          title="Conversation Escalation"
          subtitle="Enable the option for visitors to escalate to a human agent when the chatbot can't answer a question."
          input={
            <Toggle
              toggled={toggleEscalation}
              changeToggle={() => setToggleEscalation(!toggleEscalation)}
            />
          }
        />
        {toggleEscalation && (
          <div className="py-3">
            <div className=" text-sm pb-2">
              Add phrases to train the chatbot for agent escalation requests.
              More phrases improves accuracy.
            </div>
            <textarea
              value={escalationPhrases}
              onChange={(e) => handleChange(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="1. Type a sentence"
              className="w-full p-2 border-[1px] rounded-md text-sm"
              rows={5}
            />
          </div>
        )}
        <AIChatbotSettingsRow
          title="Routing Message"
          subtitle="Set the message text that offers visitors a transfer to a human agent when needed."
          input={
            <input
              value={config?.name || ""}
              onChange={(e) => {
                if (!config || !configIndex) return;
                config.name = e.target.value;
                update({ ...config }, configIndex);
              }}
              type="text"
              placeholder="Enter a name"
              className="w-full p-2 border-[1px] rounded-md text-sm"
            />
          }
        />
        <div className="pb-2">
          <CustomizableSelect options={assignment} />
        </div>
        {selectedAssignment === "team" && (
          <CustomizableSelect
            options={teams.map((team) => {
              return { label: team.name, action: () => handleTeam(team.name) };
            })}
          />
        )}

        {selectedAssignment === "agent" && (
          <CustomizableSelect
            options={users.map((user) => {
              return {
                label: user.displayName,
                action: () => handleAgent(user.id.toString())
              };
            })}
          />
        )}
        <AIChatbotSettingsRowToggle
          title="AI Summarize"
          subtitle="Enable automatic generation of conversation summaries for agents after three or more messages."
          input={
            <Toggle
              toggled={toggleAISummarize}
              changeToggle={() => setToggleAISummarize(!toggleAISummarize)}
            />
          }
        />
      </div>
    </>
  );
}
