import DaysOfWeekControl from "@/components/DaysOfWeekControl";
import TeamSelect from "@/components/messaging/TeamSelect";
import TimezonePicker from "@/components/TimezonePicker";
import React from "react";
import TimePicker from "../../../components/TimePicker";

function tryGetTimezone() {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    return "America/New_York";
  }
}

export const defaultAutoResponseModel: AutoResponseModel = {
  teamId: undefined,
  title: "",
  daysOfWeek: "",
  messageText: "",
  startMinutes: 0,
  endMinutes: 0,
  isAllDay: false,
  timezone: tryGetTimezone()
};

export interface AutoResponseModel {
  teamId: number | undefined;
  title: string;
  daysOfWeek: string;
  messageText: string;
  startMinutes: number;
  endMinutes: number;
  isAllDay: boolean;
  timezone: string;
}

interface Props {
  autoResponse: AutoResponseModel;
  onChange(updates: Partial<AutoResponseModel>): void;
}

export default function AutoResponseForm({ autoResponse, onChange }: Props) {
  return (
    <>
      <div className="flex mb-4">
        <div className="w-1/3 font-semibold">Title</div>
        <input
          name="title"
          type="text"
          className="input w-2/3"
          autoFocus
          value={autoResponse.title}
          onChange={(e) => onChange({ title: e.currentTarget.value })}
        />
      </div>
      <div className="flex mb-4">
        <div className="w-1/3 font-semibold">Team</div>
        <TeamSelect
          id="ar-team"
          className="input w-2/3"
          value={autoResponse.teamId}
          onChange={(e) =>
            onChange({ teamId: parseInt(e.currentTarget.value) })
          }
        />
      </div>
      <div className="flex mb-4">
        <div className="w-1/3 font-semibold">Days of week</div>
        <DaysOfWeekControl
          value={autoResponse.daysOfWeek}
          onChange={(value) => onChange({ daysOfWeek: value })}
        />
      </div>
      <div className="flex mb-4">
        <div className="w-1/3 font-semibold">Message Text</div>
        <textarea
          name="message-text"
          className="input w-2/3"
          autoFocus
          value={autoResponse.messageText}
          onChange={(e) => onChange({ messageText: e.currentTarget.value })}
        />
      </div>
      <div className="flex mb-4">
        <div className="w-1/3 font-semibold">All Day</div>
        <input
          id="isAllDay"
          name="isAllDay"
          type="checkbox"
          checked={autoResponse.isAllDay}
          onChange={(e) => onChange({ isAllDay: e.currentTarget.checked })}
        />
      </div>
      <div className="flex mb-4">
        <div className="w-1/3 font-semibold">Time range</div>
        <TimePicker
          className="input"
          value={autoResponse.startMinutes}
          onChange={(value) => onChange({ startMinutes: value })}
          disabled={autoResponse.isAllDay}
        />
        <span className="mx-2">-</span>
        <TimePicker
          className="input"
          value={autoResponse.endMinutes}
          onChange={(value) => onChange({ endMinutes: value })}
          disabled={autoResponse.isAllDay}
        />
      </div>
      <div className="flex mb-4">
        <div className="w-1/3 font-semibold">Timezone</div>
        <TimezonePicker
          className="input w-full"
          value={autoResponse.timezone}
          onChange={(e) => onChange({ timezone: e.currentTarget.value })}
        />
      </div>
    </>
  );
}
