import Select from "@/components/Select";
import React from "react";
import { comparatorOptions, SerializedCondition } from "../types";
import Input from "@/components/Input";

export type UrlCondition = {
  url: string;
  comparator: string;
};

export function UrlEditor({
  condition,
  onUpdate
}: {
  condition: SerializedCondition;
  onUpdate: (condition: SerializedCondition) => void;
}) {
  const urlCondition = JSON.parse(condition.data || "{}") as UrlCondition;

  function update(data: UrlCondition) {
    onUpdate({ ...condition, data: JSON.stringify(data) });
  }

  const validComparatorOptions = {
    equals: comparatorOptions.equals,
    notEquals: comparatorOptions.notEquals,
    contains: comparatorOptions.contains,
    notContains: comparatorOptions.notContains
  };

  return (
    <>
      <Select
        className="w-full border rounded-md p-2"
        options={validComparatorOptions}
        value={urlCondition.comparator}
        onChange={(v) => update({ ...urlCondition, comparator: v })}
      />
      <Input
        className="w-full border rounded-md p-2"
        value={urlCondition.url}
        onChange={(v) => update({ ...urlCondition, url: v })}
      />
    </>
  );
}
