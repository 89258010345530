import { SelectOption } from "@/types";
import Select from "@/components/Select";
import * as React from "react";
import { useMemo } from "react";
import MultiSelect, { MultiValue } from "react-select";
import { triggerDefs, WorkflowNode, Trigger } from "../types";
import { FormGroup, ToggleGroup } from "./RightAsideHelpers";
import { channelOptions } from "@/types";
import { ExpressionEditor } from "@/components/RuleBuilder2/ExpressionEditor";
import IconChevronDown from  "@velaro/velaro-shared/src/svg/stroke-icons/chevron-down.svg?react"

interface Props {
  node: WorkflowNode;
  onUpdate: (node: Partial<WorkflowNode>) => void;
}

export default function TriggerEditor({ node, onUpdate }: Props) {
  const channelSelectValue = useMemo(() => {
    if (!node.channels || node.channels.length === 0) {
      return [];
    }

    return node.channels.map((channel) => {
      return (
        channelOptions.find((option) => option.value === channel) ||
        channelOptions[0]
      );
    });
  }, [node.channels]);

  function updateChannels(selectedOptions: MultiValue<SelectOption>) {
    onUpdate({
      channels: selectedOptions.map((option) => option.value.toString())
    });
  }

  return (
    <>
      <FormGroup
        label="Trigger"
        tooltipText="A Trigger is an event that starts your workflow."
      >
        <div className="relative">
          <Select<Trigger>
            className="w-full border rounded-md p-2 pl-3 pr-10 appearance-none focus:outline-none"
            options={triggerDefs}
            value={node.trigger}
            onChange={(v) => {
              onUpdate({
                trigger: v
              });
            }}
          />
          <div className="absolute right-1 top-1/2 transform -translate-y-1/2 pointer-events-none">
            <IconChevronDown className="h-7 w-7 text-gray-300" />
          </div>
        </div>

      </FormGroup>
      <FormGroup
        label="Override Routing"
        tooltipText="With Override Routing on, this workflow will prevent default routing from taking place, preventing agents from being routed to this conversation so that the workflow can handle it as a chatbot."
      >
        <ToggleGroup
          toggled={node.overrideRouting || false}
          label={node.overrideRouting ? "Override" : "Don't Override"}
          changeToggle={() => {
            onUpdate({
              overrideRouting: !node.overrideRouting
            });
          }}
        />
      </FormGroup>
      <FormGroup
        label="Channel"
        tooltipText="Choose where you want this trigger to work. Features may vary based on the channel."
      >
        <div className="cursor-pointer">
          <MultiSelect
            isMulti
            styles={{
              control: (styles) => ({
                ...styles,
                cursor: "pointer",
                border: "1px solid #E2E8F0"
              })
            }}
            className="basic-multi-select rounded-lg"
            placeholder="Please select"
            options={channelOptions}
            onChange={updateChannels}
            value={channelSelectValue}
          />
        </div>
      </FormGroup>
      <FormGroup
        label="Conditions"
        tooltipText="Select the conditions that narrow down when this workflow runs."
      >
        <ExpressionEditor
          expression={node.triggerExpression}
          onUpdate={(expression) => onUpdate({ triggerExpression: expression })}
        />
      </FormGroup>
    </>
  );
}
