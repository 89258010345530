import React, { useMemo } from "react";
import { PlusButton, MinusButton } from "../Buttons";
import Select from "../Select";
import {
  Expression,
  Condition,
  conditionDefs,
  DayOfWeek,
  dayOfWeekDefs,
  AgentStatus,
  agentStatusDefs,
  Comparator,
  comparatorOptions
} from "./types";
import MultiSelect, { MultiValue } from "react-select";
import useTeams from "@/hooks/useTeams";
import { Channel, channelDefs } from "@/types";

export default function ExpressionEditor({
  expression,
  update
}: {
  expression: Expression;
  update(expression: Expression): void;
}) {
  const { teams, getTeamNameById } = useTeams();
  function handleTeamChange(
    selectedOptions: MultiValue<{ label: string; value: number }>
  ) {
    const selectedTeamIds = selectedOptions.map((option) => option.value);
    update({
      ...expression,
      teams: {
        ...(expression["teams"] || { comparator: "equals" }),
        value: selectedTeamIds
      }
    });
  }

  const selectedTeams = useMemo(
    () =>
      (expression["teams"]?.value || []).map((t: number) => {
        return { value: t, label: getTeamNameById(t) };
      }),
    [expression, getTeamNameById]
  );

  const teamOptions = useMemo(() => {
    return Object.values(teams).map((key) => ({
      value: key.id,
      label: key.name
    }));
  }, [teams]);

  const conditions = useMemo(
    () => Object.keys(expression) as Condition[],
    [expression]
  );

  const remainingConditionDefs = useMemo(() => {
    const c = { ...conditionDefs };
    conditions.forEach((x) => delete c[x]);
    return c;
  }, [conditions]);

  const remainingConditionKeys = useMemo(
    () => Object.keys(remainingConditionDefs) as Condition[],
    [remainingConditionDefs]
  );

  function onChangeComparator(condition: Condition, comparator: any) {
    update({
      ...expression,
      [condition]: { ...expression[condition], comparator: comparator }
    });
  }

  function renderControlForCondition(condition: Condition) {
    const value = expression[condition]?.value || "";
    function onChangeValue(value: any) {
      update({
        ...expression,
        [condition]: { ...expression[condition], value: value }
      });
    }

    switch (condition) {
      case "channel":
        return (
          <Select<Channel>
            className="input overflow-ellipsis whitespace-nowrap w-64"
            options={channelDefs}
            value={value}
            onChange={onChangeValue}
          />
        );
      case "dayOfWeek":
        return (
          <Select<DayOfWeek>
            className="input overflow-ellipsis whitespace-nowrap w-64"
            options={dayOfWeekDefs}
            value={value}
            onChange={onChangeValue}
          />
        );
      case "agentStatus":
        return (
          <Select<AgentStatus>
            className="input overflow-ellipsis whitespace-nowrap w-64"
            options={agentStatusDefs}
            value={value}
            onChange={onChangeValue}
          />
        );
      case "domain":
      case "url":
        return (
          <input
            className="input overflow-ellipsis whitespace-nowrap w-64"
            value={value}
            onChange={(e) => onChangeValue(e.target.value)}
          />
        );
      case "teams":
        return (
          <MultiSelect
            isMulti
            name="teams"
            options={teamOptions}
            value={selectedTeams}
            className="basic-multi-select w-64"
            classNamePrefix="select"
            onChange={(e) => handleTeamChange(e)}
          />
        );
    }
  }

  function changeType(oldType: Condition, newType: Condition) {
    const clone = { ...expression };
    delete clone[oldType];
    clone[newType] = { comparator: "equals", value: "" };
    update(clone);
  }

  function addCondition() {
    if (!remainingConditionKeys.length) {
      return;
    }

    const nextCondition = remainingConditionKeys[0];
    update({ ...expression, [nextCondition]: "" });
  }

  function removeCondition() {
    const last = conditions.pop();
    if (!last) {
      return;
    }
    const clone = { ...expression };
    delete clone[last];
    update(clone);
  }

  return (
    <div className="flex gap-4 items-center my-6 flex-wrap">
      {conditions.map((conditionType, i) => (
        <React.Fragment key={i}>
          <div className="flex items-center gap-2">
            <div>
              <Select<Condition>
                className="input overflow-ellipsis whitespace-nowrap w-64"
                options={{
                  ...remainingConditionDefs,
                  [conditionType]: conditionDefs[conditionType]
                }}
                value={conditionType}
                onChange={(type) => changeType(conditionType, type)}
              />
            </div>
            <Select<Comparator>
              className="input overflow-ellipsis whitespace-nowrap w-32"
              options={comparatorOptions}
              value={expression[conditionType]?.comparator || "equals"}
              onChange={(v) => onChangeComparator(conditionType, v)}
            />
            <div>{renderControlForCondition(conditionType)}</div>
          </div>
          {i < conditions.length - 1 && (
            <div className="border rounded-xl text-center bg-cornflower-blue-800 text-gray-100 text-sm px-2">
              and
            </div>
          )}
        </React.Fragment>
      ))}
      <div>
        {remainingConditionKeys.length > 0 && (
          <PlusButton label="and" onClick={addCondition} />
        )}
        {conditions.length > 1 && <MinusButton onClick={removeCondition} />}
      </div>
    </div>
  );
}
