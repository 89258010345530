import TeamSelect from "@/components/messaging/TeamSelect";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import Select from "@/components/Select";
import useApi from "@/hooks/useApi";
import React, { useMemo, useState } from "react";
import LoadingSpinner from "@/components/LoadingSpinner";

interface Props {
  onClose(): void;
  onSuccess(): void;
}

interface PhoneNumber {
  friendly_name: string;
  phone_number: string;
}

const errorMap: Record<string, string> = {
  sms_error: "An error occured while purchasing.",
  number_not_found: "No phone number found for the selected area code.",
  invalid_area_code: "Invalid area code."
};

export default function SmsIntegrationSetupModal(props: Props) {
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [error, setError] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [step, setStep] = useState<"lookup" | "purchase">("lookup");
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[] | null>(null);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  const api = useApi();

  const buttonText = useMemo(() => {
    switch (true) {
      case step == "lookup" && !loading:
        return "Find phone number";
      case step == "lookup" && loading:
        return "Finding numbers...";
      case step == "purchase" && !loading:
        return "Purchase";
      case step == "purchase" && loading:
        return "Purchasing...";
    }
  }, [loading, step]);

  const phoneNumberOptions = useMemo(() => {
    const options: Record<string, string> = {};
    if (phoneNumbers == null) {
      return options;
    }
    for (const num of phoneNumbers) {
      options[num.phone_number] = num.friendly_name;
    }
    return options;
  }, [phoneNumbers]);

  const fetchAvailablePhoneNumbers = React.useCallback(async () => {
    setLoading(true);
    const response = await api.messaging.post(
      "Sms/Integrations/GetPhoneNumbers",
      {
        countryCode: selectedCountry,
        areaCode: areaCode
      }
    );
    const numbers: PhoneNumber[] = await response.json();
    if (numbers.length === 0) {
      setError(errorMap["number_not_found"]);
    } else {
      setSelectedNumber(numbers[0].phone_number);
    }
    setPhoneNumbers(numbers);
    setStep("purchase");
    setLoading(false);
  }, [api.messaging, areaCode, selectedCountry]);

  async function submitPurchase() {
    setError("");
    setLoading(true);
    try {
      const resp = await api.messaging.post("Sms/Integrations", {
        phoneNumber: selectedNumber,
        teamId: selectedTeam
      });
      setSuccess(resp.ok);
      if (resp.ok) {
        props.onSuccess();
      } else {
        const error: string = await resp.json();
        setError(errorMap[error]);
      }
    } catch (e) {
      setError(errorMap["sms_error"]);
      console.error(e);
      setLoading(false);
    }
  }

  const isValidAreaCode = (code: string): boolean => {
    const areaCodeRegex = /^\d{3}$/;
    return areaCodeRegex.test(code);
  };

  function handleAreaCode(code: string) {
    setAreaCode(code);
    setStep("lookup");
  }

  return (
    <Modal show={true}>
      <Modal.Header
        title="Purchase Phone Number"
        onClose={() => props.onClose()}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (step == "lookup") {
            if (isValidAreaCode(areaCode)) {
              setError("");
              fetchAvailablePhoneNumbers();
            } else {
              setError(errorMap["invalid_area_code"]);
            }
          } else {
            submitPurchase();
          }
        }}
      >
        <Modal.Body>
          <div className="p-4">
            {/* <div className="mb-3">
              <label htmlFor="sms-country" className="block mb-1">
                Country
              </label>
              <CountrySelect
                setSelectedCountry={setSelectedCountry}
                value={selectedCountry}
              />
            </div> */}
            <div className="flex mb-4">
              <div className="w-1/3 font-semibold">Team</div>
              <TeamSelect
                id="ar-team"
                className="input w-2/3"
                onChange={(e) => setSelectedTeam(e.currentTarget.value)}
                value={selectedTeam}
              />
            </div>
            <div className="flex mb-4">
              <div className="w-1/3 font-semibold">Area Code</div>
              <input
                className="input w-2/3"
                onChange={(e) => handleAreaCode(e.target.value)}
                placeholder="Enter area code"
                maxLength={3}
                value={areaCode}
              />
            </div>
            {step === "purchase" && (
              <div className="flex mb-4">
                <div className="w-1/3 font-semibold">Phone Numbers</div>
                <Select
                  className="input w-2/3"
                  onChange={(value) => setSelectedNumber(value)}
                  value={selectedNumber}
                  options={phoneNumberOptions}
                />
              </div>
            )}
            <div className="text-crimson-red-500 text-sm mt-2">{error}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="p-4 flex flex-col mt-4">
            {success === false && (
              <div className="flex flex-row mb-4">
                There was an error saving your changes. Please see Integration
                Log for more details.
              </div>
            )}
            {loading ? (
              <div className="ml-auto flex">
                Saving Changes...
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ml-auto flex flex-row">
                <button
                  type="submit"
                  className="bg-cornflower-blue-500 hover:bg-cornflower-blue-700 text-white py-2 px-4 rounded-full text-sm font-medium"
                  disabled={loading}
                >
                  {buttonText}
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
