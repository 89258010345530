import LoadingSpinner from "@/components/LoadingSpinner";
import TeamSelect from "@/components/messaging/TeamSelect";
import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import NetSuiteConfigTable from "./NetSuiteConfigTable";
import NetSuiteMappingTable from "./NetSuiteMappingTable";
import useSelectedTeam from "@/hooks/useSelectedTeam";

interface NetSuiteConfig {
  id: number;
  displayName: string;
  company: string;
  teamId: number;
}

export default function NetSuite() {
  const api = useApi();
  const [configs, setConfigs] = useState<NetSuiteConfig[]>([]);
  const { teamId, updateTeamId } = useSelectedTeam();

  async function handleSetup() {
    // This is the old code for using an oauth login form
    // Currently this times out after 3600 seconds, so we are implementing another option
    // const resp = await api.messaging.get("NetSuite/Auth/LoginUrl");
    // const data = await resp.json();
    // window.location.href = data.url;

    //New API needs created for setting up the application
    //API takes the key and secret for an integration user and saves to the db

    const response = await api.messaging.post("NetSuite/Auth/SaveCredentials", {
      tenantId: "tenantId",
      displayName: "displayName",
      company: "company",
      token: "token",
      tokenSecret: "tokenSecret"
    });

    const data = await response.json();
  }

  async function oauth() {
    const resp = await api.messaging.get("NetSuite/Auth/LoginUrl");
    const data = await resp.json();
    window.location.href = data.url;
  }

  const fetchConfigs = useCallback(async () => {
    if (!teamId) {
      return;
    }
    console.log(teamId);
    const resp = await api.messaging.get(`NetSuite/Integrations/${teamId}`);
    setConfigs(await resp.json());
  }, [api.messaging, teamId]);

  const addConfig = useCallback(() => {
    fetchConfigs();
  }, [fetchConfigs]);

  useEffect(() => {
    fetchConfigs();
  }, [fetchConfigs]);

  const deleteConfig = useCallback(
    async (id: number) => {
      if (confirm("are you sure you want to delete this integration?")) {
        setConfigs((oldState) => {
          return oldState.filter((x) => x.id != id);
        });
        await api.messaging.delete(`NetSuite/Integrations/${id}`);
      }
    },
    [api.messaging]
  );

  return (
    // <div className="max-w-md pt-12">
    <div className="h-[900px]">
      <div className="h-14 border-b flex-none">
        <div className="h-full items-center w-1/2">
          <div className="w-1/2">Select Team</div>
          <TeamSelect
            className="input w-full p-2"
            onChange={(e) => updateTeamId(Number(e.currentTarget.value))}
            value={Number(teamId)}
          />
        </div>
      </div>
      <div className="p-4">
        <div className="mb-4">
          <NetSuiteConfigTable
            configs={configs}
            deleteConfig={deleteConfig}
            addConfig={addConfig}
            teamId={teamId!}
          />
        </div>
        <div className="mb-4">
          <NetSuiteMappingTable teamId={teamId!} />
        </div>
        {/* <div className="mb-4">
          <NetSuiteLogin addConfig={addConfig} />
        </div> */}
        {/* This triggers oauth, which we arent using since it only keeps you logged in for 2 hours */}
        {/* <button className="btn-blue" onClick={() => oauth()}>
          Install NetSuite integration
        </button> */}
      </div>
    </div>
  );
}
