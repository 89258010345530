import React from "react";

export default function Select<T extends string | number>({
  className,
  options,
  value,
  onChange
}: {
  className?: string;
  options: Record<T, string> | { label: string; value: string | number }[];
  value: T | null | undefined;
  onChange(value: T): void;
}) {
  function renderOptions() {
    const isArray = Array.isArray(options);
    if (!isArray) {
      const optionList = Object.keys(options) as T[];

      return (
        <>
          {optionList.map((c, i) => (
            <option key={i} value={c}>
              {(options as Record<T, string>)[c]}
            </option>
          ))}
        </>
      );
    } else {
      return (
        <>
          {(options as { label: string; value: string | number }[]).map(
            (c, i) => (
              <option key={i} value={c.value}>
                {c.label}
              </option>
            )
          )}
        </>
      );
    }
  }

  return (
    <select
      className={`cursor-pointer h-10 ${className || ""}`}
      value={value || ""}
      onChange={(e) => onChange(e.currentTarget.value as T)}
    >
      <option value={""}>-- Select --</option>
      {renderOptions()}
    </select>
  );
}
