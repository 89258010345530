import React, { useCallback, useState } from "react";
import { AIConfiguration } from "../types";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import useApi from "@/hooks/useApi";
import TeamSelect from "@/components/messaging/TeamSelect";
import LoadingSpinner from "@/components/LoadingSpinner";
import FormGroup from "@velaro/velaro-shared/src/components/FormGroup";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  config: AIConfiguration;
  scrapedUrls?: string[];
  onUpdate(config: AIConfiguration): void;
  onClose(): void;
}
export default function AIConfigurationModal({
  config,
  scrapedUrls,
  onUpdate,
  onClose
}: Props) {
  const [workingModel, setWorkingModel] = useState<AIConfiguration>(config);
  const api = useApi();
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  const save = useCallback(async () => {
    setSending(true);
    const resultJson = await api.messaging.post(
      "AIConfiguration",
      workingModel
    );
    const result = await resultJson.json();
    onUpdate(result);
    setSuccess(result.ok);
    setSending(false);
  }, [api.messaging, onUpdate, workingModel]);

  const title = workingModel.id ? "Edit" : "Add";
  return (
    <Modal show={true} maxWidth="max-w-3xl">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
      >
        <Modal.Header
          title={title + " AI Configuration"}
          onClose={() => onClose()}
        />
        <Modal.Body>
          <div className="p-4">
            <FormGroup label="Status">
              <div className="font-semibold">{workingModel.status}</div>
            </FormGroup>
            <FormGroup label="Name">
              <input
                type="text"
                className="input w-full"
                value={workingModel.name}
                max={50}
                onChange={(e) =>
                  setWorkingModel({
                    ...workingModel,
                    name: e.currentTarget.value
                  })
                }
              />
            </FormGroup>
            <FormGroup label="Team">
              <TeamSelect
                id="ar-team"
                className="input w-full"
                onChange={(e) =>
                  setWorkingModel({
                    ...workingModel,
                    teamId: e.currentTarget.value
                  })
                }
              />
            </FormGroup>
            <FormGroup label="Prompt">
              <textarea
                className="input w-full"
                placeholder="Enter a prompt to describe how the AI should function. Leave blank for a generic support agent AI."
                value={workingModel.prompt}
                onChange={(e) => {
                  setWorkingModel({
                    ...workingModel,
                    prompt: e.currentTarget.value
                  });
                }}
              />
            </FormGroup>
            <FormGroup label="Website">
              <input
                type="text"
                className="input w-full"
                value={workingModel.dataUrls[0] || ""}
                max={50}
                onChange={(e) =>
                  setWorkingModel({
                    ...workingModel,
                    dataUrls: [e.currentTarget.value]
                  })
                }
              />
            </FormGroup>

            <FormGroup label="Scraped Urls">
              <div>
                {scrapedUrls && scrapedUrls?.length > 0 && (
                  <div className="w-full">
                    {scrapedUrls?.map((url, i) => (
                      <div key={i}>
                        <input type="checkbox" className="input mr-2" />
                        <label>{url}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </FormGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="p-4 flex flex-col">
            {success === false && (
              <div className="flex flex-row mb-4">
                There was an error saving your changes. Please see Integration
                Log for more details.
              </div>
            )}
            {sending ? (
              <div className="ml-auto flex">
                Saving Changes...
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ml-auto flex">
                <PrimaryBlueButton
                  onClick={() => save()}
                  label="Save Changes"
                />
              </div>
            )}
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
