import {
  Trigger,
  WorkflowRule,
  triggerDefs,
} from "@/components/RuleBuilder/types";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import React, { useState } from "react";
import RuleFormFactory from "./ValueForms/RuleFormFactory";
import FormGroup from "@velaro/velaro-shared/src/components/FormGroup";
import Select from "@/components/Select";
import useWorkflowConfigs from "@/hooks/useWorkflowConfigs";
import RuleEditor from "./RuleEditor";
import { UseAIValue } from "./ValueForms/AIRuleForm";
import { UseAutomationValue } from "./ValueForms/AutomationRuleForm";

interface Props {
  rule?: WorkflowRule;
  close(): void;
}

export function defaultRuleValue(trigger: Trigger) {
  switch (trigger) {
    case "use-ai":
      return { aiConfig: "" } as UseAIValue;
    case "use-automation":
    default:
      return { automation: "" } as UseAutomationValue;
  }
}

export default function RuleEditModal({ rule, close }: Props) {
  const [workingModel, setWorkingModel] = useState<WorkflowRule>(
    rule || {
      id: undefined,
      trigger: "use-automation",
      priority: 1,
      expressions: [{ channel: { comparator: "equals", value: "Web" } }],
      value: { automation: "" },
    }
  );
  const { add, update, remove, saving } = useWorkflowConfigs();

  const newRule = !workingModel.id;
  const title = newRule ? "Create Rule" : "Edit Rule";

  return (
    <Modal maxWidth="max-w-5xl" show={true}>
      <>
        <Modal.Header title={title} onClose={close} />
        <Modal.Body>
          <div className="p-4">
            <FormGroup label="Priority">
              <input
                type="number"
                required
                value={workingModel.priority}
                onChange={(e) =>
                  setWorkingModel({
                    ...workingModel,
                    priority: parseInt(e.target.value),
                  })
                }
              />
            </FormGroup>
            <FormGroup label="Trigger">
              <Select
                options={triggerDefs}
                value={workingModel.trigger}
                onChange={(v) =>
                  setWorkingModel({
                    ...workingModel,
                    trigger: v,
                    value: defaultRuleValue(workingModel.trigger),
                  })
                }
              />
            </FormGroup>
            <RuleFormFactory
              value={workingModel.value}
              updateValue={(v) =>
                setWorkingModel({ ...workingModel, value: v })
              }
              trigger={workingModel.trigger}
            />
            <RuleEditor
              rule={workingModel}
              update={setWorkingModel}
              remove={() => remove(workingModel)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex p-4">
            <div className="ml-auto">
              <button
                disabled={saving}
                className="btn-green btn-xs float-right"
                onClick={() => {
                  close();
                  newRule ? add(workingModel) : update(workingModel);
                }}
              >
                {saving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </>
    </Modal>
  );
}
